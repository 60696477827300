<script setup>
import ArcLayer from "@/components/ArcLayer.vue"
import BaseMap from "@/components/BaseMap"
import DataTable from "@/components/DataTable.vue"
import FlowLayer from "@/components/FlowLayer"
import HexagonLayerWithCycles from "@/components/HexagonLayerWithCycles.vue"
import InteractiveDashboard from "@/components/InteractiveDashboard.vue"
import MapControl from "@/components/MapControl"
import TravelView from "@/components/TravelView.vue"
import { log } from "@/plugin/logger.js"
import { createCustomMapStore } from "@/store/customMapStore"
import { useI18nStore } from "@/store/localeStore"
import { useMainStore } from "@/store/mainStore"
import { useSetStore } from "@/store/setStore"
import { computed, nextTick, onMounted, onUnmounted, provide, ref } from "vue"

const props = defineProps({
  mapModuleStateName: {
    type: String,
    required: true
  }
})

const emit = defineEmits(["baseMapUpdated", "baseMapRemoved"])
const mainStore = useMainStore()
const dataStore = useSetStore()
const viewContext = ref(0)
const useMapStore = createCustomMapStore(props.mapModuleStateName)

const mapboxToken = process.env.VUE_APP_MAP_TOKEN
const zoom = ref(1.62)
const isOpen = ref(false)

const position = ref([0, 0])
const content = ref()
const cycleDataDoneFetching = ref(false)
const arcData = ref([])
const selectedHexagons = ref([])


const i18nStore = useI18nStore()
const translate = (key) => {
  return i18nStore.getTranslation(key)
}

async function addMatrixDataToContent ({ properties }) {
  // Object.entries(properties).forEach(([key, value]) => {
  //   content.value[key] = value
  // })
}


async function openMBPopup({ coordinates, properties }) {
  closePopup()
  await nextTick()
  isOpen.value = true
  position.value = [...coordinates]

  content.value = Object.fromEntries(
    Object.entries(properties).map(([key, value]) => {
      try {
        return [key, JSON.parse(value)]
      } catch (err) {
        // Silence is golden.
      }
      return [key, value]
    })
  )
  if (properties.type === "flow") {
    content.value = Object.keys(content.value)
      .filter((key) => key.includes("count"))
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: content.value[key]
        })
      }, {})

  } else if (properties.type === "location") {
    content.value = Object.keys(content.value.totals)
      .filter(key => !key.includes("internal"))
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: content.value.totals[key]
        })
      }, {})

  } else {
    content.value = Object.fromEntries(
      Object.entries(restructureData(properties)).map(([key, value]) => {
        try {
          return [key, JSON.parse(value)]
        } catch (err) {
          // Silence is golden.
        }
        return [key, value]
      })
    )
  }
}

function restructureData(originalData) {
  //properties to group
  const propertiesToGroup = [
    "duration",
    "ingoing_distance",
    "outgoing_distance",
    "ingoing_duration",
    "outgoing_duration"
  ]

  const newData = {}
  propertiesToGroup.forEach((property) => {
    // Create min, median, and max keys in the new object
    newData[property] = {
      min: originalData["min_"+property],
      median: originalData["median_"+property],
      max: originalData["max_"+property]
    }
  })
  newData.others = {}
  for (const key in originalData) {
    if (!propertiesToGroup.some((property) => key.includes(property))) {
      newData.others[key] = originalData[key]
    }
  }
  return newData
}

const hexClicked = () => {
  cycleDataDoneFetching.value = false
}

const cyclesAreUpdated = () => {
  log("info", "Cycles was updated from click on hex layer")
  cycleDataDoneFetching.value = true
  useMapStore.setLayerVisibility("cycleFlows", true)
}

const setEdgeData = (edgeData) => {
  arcData.value = edgeData
}

const setSelectedHexagons = (hexIds) => {
  if (Array.isArray(hexIds)) {
    selectedHexagons.value = hexIds
  } else if (typeof hexIds === "string" && hexIds !== "") {
    selectedHexagons.value = hexIds.split(",")
  } else {
    selectedHexagons.value = []
  }
}


const hexagonLayers = computed(() => {
  const hexagonMapLayers = useMapStore.getStateForKey("mapLayers")["Hexagon"]

  if (!hexagonMapLayers || hexagonMapLayers.length < 1) {
    return []
  }
  return hexagonMapLayers
    .map((layer, index) => {
      const dataLoaded = dataStore.getAggregate(layer.accessorKey)
      if (dataLoaded) {
        return {
          ...layer,
          layerName: `${layer.setName}_${index}`
        }
      }
    })
    .filter(Boolean)
})

const tripLayers = computed(() => {
  const tripMapLayers = useMapStore.getStateForKey("mapLayers")["Trip"]

  if (!tripMapLayers || tripMapLayers.length < 1) {
    return []
  }
  return tripMapLayers
    .map((layer, index) => {
      const dataLoaded = dataStore.getAggregate(layer.accessorKey)
      if (dataLoaded) {
        return {
          ...layer,
          layerName: `${layer.setName}_${index}`
        }
      }
    })
    .filter(Boolean)
})


const hexagonLayersInMap = computed(() => {
  const hexagonMapLayers = useMapStore.getStateForKey("mapLayers")["Hexagon"]
  if (!hexagonMapLayers || hexagonMapLayers.length < 1) {
    return []
  }
  return hexagonMapLayers
    .map((layer, index) => {
      const dataLoaded = useMapStore.getAggregate(layer.accessorKey)
      if (dataLoaded) {
        return {
          ...layer,
          layerName: `${layer.setName}_${index}`
        }
      }
    })
    .filter(Boolean)
})

const tripLayersInMap = computed(() => {
  const tripMapLayers = useMapStore.getStateForKey("mapLayers")["Trip"]

  if (!tripMapLayers || tripMapLayers.length < 1) {
    return []
  }
  return tripMapLayers
    .map((layer, index) => {
      const dataLoaded = useMapStore.getAggregate(layer.accessorKey)
      if (dataLoaded) {
        return {
          ...layer,
          layerName: `${layer.setName}_${index}`
        }
      }
    })
    .filter(Boolean)
})


const cycleLayers = computed(() => {
  const cycleLayers = useMapStore.getStateForKey("mapLayers")["Cycles"]
  if (!cycleLayers) return []
  return cycleLayers
})

const layersInMap = computed(() => {
  return [...hexagonLayers.value, ...tripLayers.value]
})

const closePopup = () => {
  isOpen.value = false
}

const baseMap = ref()
const mapStyle = computed(() => {
  return "mapbox://styles/" + mainStore.getSelectedBaseMap
})

const topLocations = computed(() => {
  const locations = dataStore.getAggregate("topLocations")
  console.log("topLocations", locations)
  return Array.isArray(locations) ? locations : []
})

const allVehicles = computed(() => {
  const vehicles = dataStore.getAggregate("vehicleAggregates")
  console.log("vehicleAggregates", vehicles)
  return Array.isArray(vehicles) ? vehicles : []
})

const initLoadWithMapData = async () => {
  const set = [...dataStore.getDataSetOptionsForMap]
  if (props.mapModuleStateName === "mainMap") {
    // Add the hexagon dataset for the 7 days on minimap automatically
    const hexes = {...set[0]}
    hexes["visualisedAs"] = "Hexagon"
    useMapStore.setMapLayer(hexes)
    const autoOpenTime = 10000
    setTimeout(() => {
      isOpen.value = true
    }, autoOpenTime)
  }
  if (props.mapModuleStateName === "mapState_1") {
    // Add the edges dataset on right sided map automatically
    const edges = {...set[1]}
    edges["visualisedAs"] = "Trip"
    useMapStore.setMapLayer(edges)
    isOpen.value = false    
  }
}

onMounted(() => {
  log("info", "Initiated a 'MapAppView' with " + props.mapModuleStateName + " name.")
  initLoadWithMapData()  
  emit("baseMapUpdated", baseMap.value?.map)
})

onUnmounted(() => {
  emit("baseMapRemoved")
})

provide(props.mapModuleStateName, useMapStore)

const hexagonHeaders = ref([
  { name: translate("table->Hexagon id"), unique: true, align: "start", sortable: false, prop: "hexagons", sorted: null },
  { name: translate("table->Location name"), prop: "hexagon_name", sortable: true, editable: true, sorted: null },
  { name: translate("table->Count of standstills"), prop: "no_stops", sortable: true, sorted: null },
  { name: translate("table->Unique vehicles"), prop: "vehicles", sortable: true, sorted: null },
  { name: translate("table->Median standstill (minutes)"), prop: "median_duration", sortable: true, sorted: null },
  { name: translate("table->Total standstill (minutes)"), prop: "sum_duration", sortable: true, sorted: null },
  { name: translate("table->Potential time to save (minutes)"), prop: "potential_saving", sortable: true, sorted: null },
  { name: translate("table->Total weight loaded (tonnes)"), prop: "weight_loaded", sortable: true, sorted: null },
  { name: translate("table->Total weight unloaded (tonnes)"), prop: "weight_unloaded", sortable: true, sorted: null }
])

const vehicleHeaders = ref([
  { name: translate("table->Vehicle id"), unique: true, align: "start", sortable: true, prop: "reg_no", sorted: true },
  {name: translate("table->Count of standstills"), prop: "no_stops", sortable: true, sorted: null },
  { name: translate("table->Median standstill (minutes)"), prop: "median_duration", sortable: true, sorted: null },
  { name: translate("table->Total standstill (minutes)"), prop: "sum_duration", sortable: true, sorted: null },
  { name: translate("table->Total standstill"), prop: "sum_duration_alias", sortable: true, sorted: null },
  { name: translate("data->Fuel consumption (litre/100km)"), prop: "fuel_consumption", sortable: true, sorted: null },
  { name: translate("data->Total fuel consumption (litres)"), prop: "sum_outgoing_trip_fuel", sortable: true, sorted: null },
  { name: translate("data->Total distance driven (km)"), prop: "sum_outgoing_trip_odometer", sortable: true, sorted: null },
  { name: translate("table->Potential time to save (minutes)"), prop: "potential_saving", sortable: true, sorted: null },
  { name: translate("table->Total weight loaded (tonnes)"), prop: "weight_loaded", sortable: true, sorted: null },
  { name: translate("table->Total weight unloaded (tonnes)"), prop: "weight_unloaded", sortable: true, sorted: null }
  ])
const tripHeaders = ref([
  { name: translate("table->Origin"), unique: true, align: "start", sortable: false, prop: "hexagons_origin", sorted: null },
  { name: translate("table->Destination"), prop: "hexagon_destination", sortable: false, sorted: null },
  { name: translate("table->Number of trips"), prop: "no_of_trips", sortable: true, sorted: null },
  { name: translate("table->Number of vehicles"), prop: "no_of_vehicles", sortable: true, sorted: null },
  { name: translate("table->Median trip time"), prop: "median_duration", sortable: true, sorted: null },
  { name: translate("table->Median trip weight"), prop: "median_trip_weight", sortable: true, sorted: null }
])

</script>

<template>
  <v-btn-toggle
    v-show="layersInMap.length > 0"
    v-model="viewContext"
    class="view-context-toggle"
    density="compact"
    shaped
    mandatory
    divided
  >
    <v-btn v-show="props.mapModuleStateName === 'mainMap'">
      <font-awesome-icon
        icon="pie-chart"
        style="cursor: default;color: deeppink;height: 15px;"
      />
    </v-btn>

    <v-btn>
      <font-awesome-icon
        icon="map-location"
        style="cursor: default;color: deeppink;height: 15px;"
      />
    </v-btn>

    <v-btn v-show="props.mapModuleStateName === 'mainMap'">
      <font-awesome-icon
        icon="truck"
        style="cursor: default;color: deeppink;height: 15px;"
      />
    </v-btn>
    <v-btn>
      <font-awesome-icon
        icon="location-dot"
        style="cursor: default;color: deeppink;height: 15px;"
      />
    </v-btn>
  </v-btn-toggle>

  <BaseMap
    ref="baseMap"
    class="map"
    :access-token="mapboxToken"
    :map-style="mapStyle"
    :zoom="zoom"
    :max-zoom="18"
    :show-search-input="true && (props.mapModuleStateName === 'mainMap')"
  >
    <DataTable
      v-for="(layer, index) in hexagonLayersInMap"
      v-show="viewContext === 1"
      :key="layer.setName + '_' + index + '_table_locations'"
      class="table"
      :layer="layer"
      :headers="hexagonHeaders"
      :named-module="props.mapModuleStateName"
      :row-clickable="true"
    />
    <DataTable
      v-show="viewContext === 2"
      class="table"
      :layer="{accessorKey:'vehicleAggregates',layerName:'locations_0'}"
      :headers="vehicleHeaders"
      :named-module="props.mapModuleStateName"
      :row-clickable="false"
    />
    <DataTable
      v-for="(layer, index) in tripLayersInMap"
      v-show="viewContext === 1"
      :key="layer.setName + '_' + index + '_table_trips'"
      class="table"
      :layer="layer"
      :headers="tripHeaders"
      :named-module="props.mapModuleStateName"
      :row-clickable="true"
    />

    <InteractiveDashboard
      v-show="props.mapModuleStateName === 'mainMap'"
      :view-context="viewContext"
      :map-module-state-name="props.mapModuleStateName"
    />
    <map-control
      :key="position"
      :layers="layersInMap"
      :named-module="props.mapModuleStateName"
      :hexagon-layers="hexagonLayers"
      :flow-layers="tripLayers"
      :open-charts="isOpen"
    />
    <travel-view
      v-if="tripLayers.length > 0"
      :map-layers="tripLayers"
    />
    <HexagonLayerWithCycles
      v-for="(layer, index) in hexagonLayers"
      :id="layer.setName + '_' + index"
      :key="layer.setName + '_' + index + '_hexagon_layer'"
      :layer-id="layer.setName + '_' + index"
      :data-function="layer.accessorInState"
      :named-module="props.mapModuleStateName"
      :key-for-scale="layer.colourKey"
      :accessor-key-for-data-function="layer.accessorKey"
      @cycle-data-refreshed="cyclesAreUpdated"
      @show-arcs="setEdgeData"
    />
    <FlowLayer
      v-for="(layer, index) in tripLayers"
      :id="layer.setName + '_' + index"
      :key="layer.setName + '_' + index + '_trip_layer'"
      :get-data-function="layer.accessorInState"
      :named-module="props.mapModuleStateName"
      :accessor-key-for-data-function="layer.accessorKey"
      @mb-matrix-loaded="addMatrixDataToContent"
      @mb-feature-click="openMBPopup"
    />
    <FlowLayer
      v-for="(layer, index) in cycleLayers"
      :id="layer.setName + '_' + index"
      :key="layer.setName + '_' + index + '_cycle_layer'"
      :get-data-function="layer.accessorInState"
      :named-module="props.mapModuleStateName"
      :accessor-key-for-data-function="layer.accessorKey"
      @mb-feature-click="openMBPopup"
    />
    <ArcLayer
      :id="'clickedHexes_0'"
      :origin-key="'hexagons_origin'"
      :destination-key="'hexagon_destination'"
      :width-key="'median_trip_weight'"
      :height-key="'median_trip_odometer'"
      :named-module="props.mapModuleStateName"
      :edge-data="arcData"
      @mb-feature-click="openMBPopup"
    />
  </BaseMap>
</template>

<style>
.table {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  z-index: 9;
}

.map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.mapboxgl-popup-content {
  word-wrap: break-word;
  width: 300px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
  font-size: 12px;
  font-family: "Scania Sans Semi Condensed";
  word-break: break-word;
}

th, td {
  padding: 5px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
  cursor: pointer;
}

th span {
  font-size: 1em;
}

.view-context-toggle {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  margin-top: 5px;
  margin-left: 5px;
}
metric-card {
  display: flex;
  flex-direction: column;
  padding: 25px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  height: 200px;
  width: 200px;
}

</style>
