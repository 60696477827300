<script setup>
import { useEventsBinding, useMap } from "@/composables"
import { log } from "@/plugin/logger.js"
import { authService } from "@/services/AuthService"
import { h3Cache, humanFriendlyH3Name } from "@/services/h3Utils"
import { computed, inject, onMounted, onUnmounted, ref, unref, watch } from "vue"
import {useSetStore} from "@/store/setStore"
import {useI18nStore} from "@/store/localeStore"
const props = defineProps({
  layer: {
    type: Object,
    required: true
  },
  headers: {
    type: Array,
    required: true
  },
  selectedIds: {
    type: Array,
    default: () => []
  },
  namedModule: {
    type: String,
    required: true
  },
  rowClickable: {
    type: Boolean,
    required: true
  }
})

const useCustomStore = inject(props.namedModule)
const resolvedNames = ref({})
const pendingResolution = ref(new Set())
const nameHexagonAPI = createWorker()
const hasChanged = ref([])
const nameModel = ref({})
const isLoaded = ref(false)
const start = ref(0)
const items = ref([])
const finished = ref(false)
const tableContainer = ref(null)
const selectedIndices = ref([])
const lastClickedIndex = ref(null)
const { map } = useMap()
const dataStore = useSetStore()
const i18nStore = useI18nStore()
const mapevents = [
  "mapfeaturesselected", "deckfeaturesselected"
]
// eslint-disable-next-line vue/valid-define-emits
const emit = defineEmits()
useEventsBinding(emit, map, mapevents, props.layer.layerName+"_tbl")

const itemsPerPage = 40
let currentPage = ref(0)

const sortState = ref({
  prop: null,
  direction: null // 'asc' or 'desc'
})

function createWorker() {
  return new Worker(
      new URL("../web-worker/namehexagonWorker.js", import.meta.url),
      { type: "module" }
  )
}

const asBearerToken = (token) => {
  return `Bearer ${token}`
}

const createLocationItem = (name, id) => {
  const timestamp = new Date().toISOString()
  const editingUser = authService.keycloak.idTokenParsed ? authService.keycloak.idTokenParsed.email : "unknown"

  return [{name: name, hex_id: id, created: timestamp, edited_by: editingUser}]
}

const resolveHexagonName = async (hexId) => {
  if (pendingResolution.value.has(hexId)) return

  if (resolvedNames.value[hexId]) return

  pendingResolution.value.add(hexId)
  try {
    const name = await humanFriendlyH3Name(hexId, "reverse_geocode")
    resolvedNames.value[hexId] = name
  } catch (error) {
    console.error(`Failed to resolve name for ${hexId}:`, error)
    resolvedNames.value[hexId] = hexId
  } finally {
    pendingResolution.value.delete(hexId)
  }
}

const getDisplayName = (hexId) => {
  // First check our local resolved names
  if (resolvedNames.value[hexId]) {
    return resolvedNames.value[hexId]
  }
  // Then check the cache
  const cachedName = h3Cache.get(hexId)
  if (cachedName) {
    resolvedNames.value[hexId] = cachedName
    return cachedName
  }
  return translate("table->Click to get address")
}
const translate = (key) => {
  return i18nStore.getTranslation(key)
}
const handleHexagonNaming = (name, id) => {
  authService.keycloak.updateToken(90)
  const token = asBearerToken(authService.keycloak.token)
  const hexagonEndpoint = process.env.VUE_APP_NAME_LOCATION_API_B
  const postMessageToAPI = (API, logMessage) => {
    const customer = authService.keycloak.tokenParsed.customer.external_customer_reference

    API.postMessage({
      method: "setHexagonName",
      args: [token, hexagonEndpoint,
        {
          customer: customer
        },
        createLocationItem(name, id)
      ]
    })
    log("info", logMessage)
  }

  postMessageToAPI(nameHexagonAPI, `Change the name of ${id} to ${name} `)
  useCustomStore.setAggregateProp(props.layer.accessorKey, "hexagons", id, "hexagon_name", name)
}

const loadMoreItems = () => {
  const nextPage = currentPage.value + 1
  const nextItems = dataInMap.value.slice(currentPage.value * itemsPerPage, nextPage * itemsPerPage)
  if (nextItems.length) {
    items.value = [...items.value, ...nextItems]
    currentPage.value = nextPage
  }
}

const handleScroll = (event) => {
  const { scrollTop, clientHeight, scrollHeight } = event.target
  if (scrollTop + clientHeight >= scrollHeight - 5) {
    loadMoreItems()
  }
}

const headers = ref(props.headers)

const uniqueIdProp = computed(() => {
  const uniqueHeader = headers.value.find(header => header.unique)
  return uniqueHeader ? uniqueHeader.prop : null
})

const sortByColumn = (header) => {
  if (!header.sortable) return

  const newDirection = sortState.value.prop === header.prop && sortState.value.direction === "asc" ? "desc" : "asc"
  sortState.value = { prop: header.prop, direction: newDirection }

  headers.value.forEach(h => {
    h.sorted = h.prop === header.prop ? newDirection : null
  })

  items.value = []
  currentPage.value = 0
  loadMoreItems()
}


const dataInMap = computed(() => {
  let sortedData = []

  // Check if props.layer and props.layer.accessorKey exist
  if (props.layer && props.layer.accessorKey) {
    if (!useCustomStore.getAggregate(props.layer.accessorKey)) {
      sortedData = dataStore.getAggregate(props.layer.accessorKey) || []
    } else {
      sortedData = useCustomStore.getAggregate(props.layer.accessorKey) || []
    }

    // Ensure sortedData is an array before calling .flat()
    sortedData = Array.isArray(sortedData) ? sortedData.flat() : []
  }

  if (sortedData.length > 0) {
    const selectedItems = sortedData.filter(item =>
        item && uniqueIdProp.value && selectedIndices.value.includes(item[uniqueIdProp.value])
    )
  sortedData = sortedData.filter(item => !selectedItems.includes(item))

    if (sortState.value && sortState.value.prop) {
    sortedData.sort((a, b) => {
      const prop = sortState.value.prop
      let valA = a[prop], valB = b[prop]

      // Convert to comparable formats (number, date, or lowercase string)
      if (!isNaN(parseFloat(valA)) && !isNaN(parseFloat(valB))) {
        valA = parseFloat(valA)
        valB = parseFloat(valB)
      } else if (Date.parse(valA) && Date.parse(valB)) {
        valA = new Date(valA)
        valB = new Date(valB)
      } else {
        valA = valA.toString().toLowerCase()
        valB = valB.toString().toLowerCase()
      }

      // Sort based on direction
      if (valA < valB) return sortState.value.direction === "asc" ? -1 : 1
      if (valA > valB) return sortState.value.direction === "asc" ? 1 : -1
      return 0
    })
  }

  return [...selectedItems, ...sortedData]
  }
  return []
})


const handleRowClick = (item, index, event) => {
  const target = event.target
  if (!uniqueIdProp.value || !target.getAttribute("data-type")) {
    log("info", "Unique ID property not defined.")
    return
  }

  const id = item[uniqueIdProp.value] // Use the dynamically determined unique ID property
  if (event.ctrlKey && lastClickedIndex.value !== null) {
    const rangeStart = Math.min(index, lastClickedIndex.value)
    const rangeEnd = Math.max(index, lastClickedIndex.value)
    const idsInRange = items.value.slice(rangeStart, rangeEnd + 1).map(it => it[uniqueIdProp.value])

    if (selectedIndices.value.includes(id)) {
      selectedIndices.value = selectedIndices.value.filter(selectedId => !idsInRange.includes(selectedId))
    } else {
      selectedIndices.value = [...new Set([...selectedIndices.value, ...idsInRange])]
    }
  } else {
    if (selectedIndices.value.includes(id)) {
      selectedIndices.value = selectedIndices.value.filter(selectedId => selectedId !== id)
    } else {
      selectedIndices.value.push(id)
    }
  }
  toggleFeatureSelected()
  lastClickedIndex.value = index
}

const copySelectedRowsToClipboard = () => {
  // Todo:
  // any better solution for this??
  const selectedRowsData = items.value.filter(item =>
      selectedIndices.value.includes(item[uniqueIdProp.value])
  )

  // Format the data as a string suitable for Excel
  const clipboardString = selectedRowsData.map(row =>
      headers.value.map(header => `"${row[header.prop]}"`).join("\t") // Use tab character for column separation
  ).join("\n") // Use newline character for row separation

  // Create a temporary textarea element
  const textarea = document.createElement("textarea")
  textarea.value = clipboardString
  document.body.appendChild(textarea)
  textarea.select()
  try {
    const successful = document.execCommand("copy")
    const msg = successful ? "successfully" : "unsuccessfully"
    log("info", `Data was copied ${msg} to clipboard.`)
  } catch (err) {
    log("error", "Failed to copy data to clipboard using execCommand.")
  }

  // Remove the temporary element
  document.body.removeChild(textarea)
}

// Handle keydown events
const handleKeydown = event => {
  if (event.ctrlKey && event.key === "c") {
    event.preventDefault() // Prevent the default copy action
    copySelectedRowsToClipboard()
  }
}

const getSelectedSum = computed(() => {
  const selectedRowsData = items.value.filter(item =>
      selectedIndices.value.includes(item[uniqueIdProp.value])
  )
  const sum = selectedRowsData.reduce((accumulator, currentObject) => accumulator + parseFloat(currentObject[sortState.value.prop]), 0)

  return sum ? "Sum: " + sum : translate("table->Select a column")
})

const getSelectedAvg = computed(() => {
  const selectedRowsData = items.value.filter(item =>
      selectedIndices.value.includes(item[uniqueIdProp.value])
  )

  const sum = selectedRowsData.reduce((accumulator, currentObject) => accumulator + parseFloat(currentObject[sortState.value.prop]), 0)
  return sum ? "Average: " + (sum / selectedIndices.value.length) : translate("table->Select a column")
})

const getAllSum = computed(() => {
  const sum =  dataInMap.value.reduce((accumulator, currentObject) => accumulator + parseFloat(currentObject[sortState.value.prop]), 0)
  return sum ? "Sum: " + sum : translate("table->Select a column")
})

const getAllAvg = computed(() => {
  const sum = dataInMap.value.reduce((accumulator, currentObject) => accumulator + parseFloat(currentObject[sortState.value.prop]), 0)
  return sum ? "Average: " + (sum / dataInMap.value.length) : translate("table->Select a column")
})

const setIdAsUnchanged = (id) => {
  const index = hasChanged.value.indexOf(id)
  hasChanged.value.splice(index, 1)
}

const checkIfItemChanged = (id) => {
  return hasChanged.value.includes(id)
}

const onInputBlur = (id, evt)  => {
  if (evt.target.value) {
    handleHexagonNaming(evt.target.value, id)
    hasChanged.value.push(id)
    nameModel.value[id] = evt.target.value
  }
}

const toggleFeatureSelected = () => {
  if (unref(map)) {
    const layerName = props.layer.layerName
    if (typeof unref(map).getLayer(layerName) !== "undefined") {
      unref(map).fire("deckfeaturesselected", { layerName: layerName, featureIds: selectedIndices.value, skipTableUpdate: true })
    }
  }
}

const handleUpdatedFeatures = (event) => {
  if (event.layerName === props.layer.layerName) {
    selectedIndices.value = Array.isArray(event.featureIds) ?
        [...event.featureIds] :
        [event.featureIds]
  }
}

onMounted(() => {
  isLoaded.value = true
  loadMoreItems()
  window.addEventListener("keydown", handleKeydown)
  map.value.on("mapfeaturesselected", handleUpdatedFeatures)
})

onUnmounted(() => {
  window.removeEventListener("keydown", handleKeydown)
})

watch(() => dataInMap.value, (newVal) => {
  items.value = []
  currentPage.value = 0
  loadMoreItems()
}, { immediate: true })

</script>


<template>
  <div
    v-if="isLoaded"
    class="data-table"
  >
    <div class="top-block" />

    <div
      ref="tableContainer"
      class="infinite-table"
      @scroll="handleScroll"
    >
      <table>
        <thead>
          <tr>
            <th
              v-for="header in headers"
              :key="header.prop"
              :class="{ 'is-selected': header.prop === sortState.prop }"
              :align="header.align"
              @click="header.sortable && sortByColumn(header)"
            >
              {{ header.name }}
              <span v-if="header.sorted === 'asc'">▲</span>
              <span v-if="header.sorted === 'desc'">▼</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="index"
            :class="{ 'is-selected': selectedIndices.includes(item[uniqueIdProp]) }"
            @click="props.rowClickable?handleRowClick(item, index, $event):null"
          >
            <td
              v-for="header in headers"
              :key="header.prop"
              :data-type="header.prop"
              class="data-item"
            >
              <template v-if="header.editable">
                <v-text-field
                  v-model="nameModel[item.hexagons]"
                  :hint="translate(' table->Set a new name for your site') "
                  variant="underlined"
                  class="editable-data-item"
                  density="compact"
                  :label="!item[header.prop] || item[header.prop] === '' ? '' : item[header.prop]"
                  :placeholder="!item[header.prop] || item[header.prop] === '' ? translate(' table->Name your site')  : ''"
                  @click="setIdAsUnchanged(item.hexagons)"
                  @blur="onInputBlur(item.hexagons, $event)"
                >
                  <template
                    v-if="checkIfItemChanged(item.hexagons)"
                    #append-inner
                  >
                    <tds-icon
                      class="stored-new-name"
                      name="tick"
                      size="12px"
                    />
                  </template>
                </v-text-field>
              </template>
              <template v-else>
                <template v-if="header.prop === 'hexagons'">
                  <span
                    class="clickable-hex"
                    @click="resolveHexagonName(item[header.prop])"
                  >
                    {{ getDisplayName(item[header.prop]) }}
                  </span>
                </template>
                <template v-else>
                  {{ item[header.prop] }}
                </template>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      ref="tableFooter"
      class="infinite-table-footer"
    >
      <table>
        <tfoot>
          <tr v-if="selectedIndices.length < 1">
            <td>
              {{ translate("table->Count") }}: {{ dataInMap.length }}
            </td>

          </tr>
          <tr v-if="selectedIndices.length > 0">
            <td>
              {{ translate("table->Count") }}: {{ dataInMap.length }}
            </td>

          </tr>
        </tfoot>
      </table>
    </div>
    <div class="bottom-block" />
  </div>
  <div v-else>
    <slot name="loader" />
  </div>
</template>


<style scoped>
.top-block, .bottom-block {
  position: relative;
  height: 8%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 4;
}

.bottom-block {
  height: 12%;
  z-index: 6;
}

.infinite-table {
  height: 77%;
  width: 100%;
  overflow: auto;
  background-color: #f2f2f2;
  position: relative;
  z-index: 8;
}

.infinite-table-footer {
  height: 3%;
  width: 100%;
  background-color: #f2f2f2;
  border-top: 1px solid deeppink;
  font-weight: bold;
}

thead th {
  position: sticky;
  top: 0;
  border-top: 3px solid #ddd;
  border-bottom: 1px solid deeppink;
}

.data-item:hover {
  background-color: pink;
}

.is-selected {
  background-color: pink;
}

.editable-data-item {
  padding: 0 !important;
  font-size: 12px !important;
  font-family: "Scania Sans";
  min-width: 180px;
}

.data-item {
  font-size: 12px !important;
  font-family: "Scania Sans";
}

.stored-new-name {
  color: var(--tds-green-400);
}

.clickable-hex {
  cursor: pointer;
  color: var(--tds-blue-400);
  text-decoration: underline;
}

.clickable-hex:hover {
  color: var(--tds-blue-600);
}
</style>