import * as h3 from "h3-js"

// Create a cache that persists in localStorage
const CACHE_KEY = "h3_location_cache"

// Initialize cache from localStorage
const initializeCache = () => {
  try {
    const savedCache = localStorage.getItem(CACHE_KEY)
    return savedCache ? new Map(JSON.parse(savedCache)) : new Map()
  } catch (error) {
    console.warn("Failed to load cache from localStorage:", error)
    return new Map()
  }
}

const cache = initializeCache()

export const h3Cache = {
  get(h3Index) {
    const cachedValue = cache.get(h3Index)
    if (cachedValue) {
      console.log(`Cache HIT for ${h3Index}: ${cachedValue}`)
    } else {
      // console.log(`Cache MISS for ${h3Index}`)
    }
    return cachedValue
  },
  
  set(h3Index, name) {
    console.log(`Caching ${h3Index}: ${name}`)
    cache.set(h3Index, name)
    // Save to localStorage
    try {
      localStorage.setItem(CACHE_KEY, JSON.stringify([...cache]))
      console.log("Cache saved to localStorage")
    } catch (error) {
      console.warn("Failed to save cache to localStorage:", error)
    }
  },

  // Optional: method to clear cache if needed
  clear() {
    cache.clear()
    localStorage.removeItem(CACHE_KEY)
    console.log("Cache cleared")
  }
}

export async function humanFriendlyH3Name(h3Index) {
  return mapBoxReverseGeocode(h3Index)
}

export async function mapBoxReverseGeocode(h3Index) {
  console.log(`Resolving name for h3Index: ${h3Index}`)
  
  // Check cache first
  const cachedName = h3Cache.get(h3Index)
  if (cachedName) {
    console.log(`Using cached name for ${h3Index}`)
    return cachedName
  }

  console.log(`Making API request for ${h3Index}`)
  const [lat, lon] = h3.cellToLatLng(h3Index)
  
  try {
    const response = await fetch(`https://api.mapbox.com/search/geocode/v6/reverse?longitude=${lon}&latitude=${lat}&access_token=${process.env.VUE_APP_MAP_TOKEN}`)
    const data = await response.json()

    if (data && data.features && data.features.length > 0) {
      const feature = data.features[0]
      const properties = feature.properties
      const context = properties.context || {}

      let locationName = "Unknown"

      if (properties.name) {
        locationName = properties.name
      } else if (context.street?.name) {
        locationName = context.street.name
      } else if (context.postcode?.name) {
        locationName = context.postcode.name
      } else if (context.place?.name) {
        locationName = context.place.name
      } else if (context.region?.name) {
        locationName = context.region.name
      } else if (context.country?.name) {
        locationName = context.country.name
      }

      const name = `${locationName} (${lat.toFixed(4)}, ${lon.toFixed(4)})`
      console.log(`Successfully resolved ${h3Index} to "${name}"`)
      h3Cache.set(h3Index, name)
      return name
    } else {
      const fallback = `Unknown (${lat.toFixed(4)}, ${lon.toFixed(4)})`
      console.log(`No location data found for ${h3Index}, using fallback: "${fallback}"`)
      h3Cache.set(h3Index, fallback)
      return fallback
    }
  } catch (error) {
    console.error(`API request failed for ${h3Index}:`, error)
    const fallback = `Hex_${lat.toFixed(4)}_${lon.toFixed(4)}`
    console.log(`Using error fallback for ${h3Index}: "${fallback}"`)
    h3Cache.set(h3Index, fallback)
    return fallback
  }
}
