<script setup>

import AggregateCard from "@/components/AggregateCard.vue"
import BarChart from "@/components/D3graphs/BarChart.vue"
import BarChartWithPopup from "@/components/D3graphs/BarChartWithPopup.vue"
import DraggableChart from "@/components/D3graphs/DraggableChart.vue"
import WeekdayChart from "@/components/D3graphs/WeekdayChart.vue"
import LocationAggregatePanel from "@/components/LocationAggregatePanel.vue"
import ToggleVisibilityBtn from "@/components/ToggleVisibilityBtn.vue"
import { useEventsBinding, useMap } from "@/composables"
import { log } from "@/plugin/logger"
import { authService } from "@/services/AuthService"
import { h3Cache, humanFriendlyH3Name } from "@/services/h3Utils"
import { useI18nStore } from "@/store/localeStore"
import { useSetStore } from "@/store/setStore"
import { computed, inject, onMounted, ref, watch } from "vue"

const i18nStore = useI18nStore()
const translate = (key) => {
  return i18nStore.getTranslation(key)
}

const props = defineProps({
  namedModule: {
    type: String,
    required: true
  }
})

function createWorker() {
  return new Worker(
      new URL("../web-worker/namehexagonWorker.js", import.meta.url),
      { type: "module" }
  )
}

const nameHexagonAPI = createWorker()
const nameModel = ref({})
const hasChanged = ref([])
const dataStore = useSetStore()
const useCustomStore = inject(props.namedModule)
const locationSelected = ref(false)
const selectedItem = ref([])
const activeOnEdit = ref("")
const { map } = useMap()
const title = translate("data->All your locations")

const mapevents = [
  "mapfeaturesselected", "deckfeaturesselected"
]

// eslint-disable-next-line vue/valid-define-emits
const emit = defineEmits()
useEventsBinding(emit, map, mapevents, props.namedModule+"_charts")

const handleUpdatedFeatures = async (event) => {
  if (useCustomStore.getMapLayersWithName(event.layerName)) {
    locationSelected.value = event.featureIds && event.featureIds.length > 0

    if (!event.featureIds || event.featureIds.length === 0) {
      activeOnEdit.value = null
      selectedItem.value = []
      return
    }

    const features = Array.isArray(event.featureIds) ?
        [...event.featureIds] :
        [event.featureIds]
    if (features.length > 0) {
      const aggregateData = useCustomStore.getAggregate("locationAggregates")
      const aggregateDataMap = new Map(aggregateData.map(obj => [obj.hexagons, obj]))
      selectedItem.value = features.map(locationId => aggregateDataMap.get(locationId))
      activeOnEdit.value = selectedItem.value[0]
      if(activeOnEdit.value){
        // Get human friendly name for the location if it doesn't have a custom name
        if (!activeOnEdit.value.hexagon_name || activeOnEdit.value.hexagon_name === "") {
          const friendlyName = await humanFriendlyH3Name(activeOnEdit.value.hexagons, "reverse_geocode")
          nameModel.value[activeOnEdit.value.hexagons] = friendlyName
        }
      }
    } else {
      selectedItem.value = []
      activeOnEdit.value = ""
    }
  }
}

const charts = ref({
  standstill: {index: 0, showThis: true},
  loadChange: {index: 1, showThis: true},
  weekday: {index: 2, showThis: true}
})

const chartHeight = ref(240) // Same as in DraggableChart
const chartWidth = ref(400) // Same as in DraggableChart

const dataReadyForMap = (aggregateName) => {
  if (locationSelected.value) {
    const aggregateData = useCustomStore.getAggregate(aggregateName)
    return aggregateData && aggregateData.length > 0
  } else {
    const aggregateData = dataStore.getAggregate(aggregateName)
    return aggregateData && aggregateData.length > 0
  }
  return false
}

const calculateXPosition = (chartName) => {
  return 0
}

const showThis = (chartName) => {
  return charts.value[chartName].showThis
}

const yesterdayAggregate = (aggregateName, key) => {
  if (locationSelected.value) {
    const aggregateData = useCustomStore.getAggregate(aggregateName)
    if (aggregateData && aggregateData.length > 0) {
      return key.includes("alias")?aggregateData[aggregateData.length - 1][key]:Number(aggregateData[aggregateData.length - 1][key]).toFixed()
    }
  } else {
    const aggregateData = dataStore.getAggregate(aggregateName)
    if (aggregateData && aggregateData.length > 0) {
      return key.includes("alias")?aggregateData[aggregateData.length - 1][key]:Number(aggregateData[aggregateData.length - 1][key]).toFixed()
    }
  }

}

const summedTotal = (aggregateName, key) => {
  if (locationSelected.value) {
    const aggregateData = useCustomStore.getAggregate(aggregateName)
    if (aggregateData && aggregateData.length > 0) {
      //return Number.parseInt(useCustomStore.getAggregate(aggregateName).reduce((acc, curr) => acc + curr[key], 0))
      return key.includes("alias")?aggregateData[aggregateData.length - 1][key]:Number(aggregateData[aggregateData.length - 1][key]).toFixed()
    }
  } else {
    const aggregateData = dataStore.getAggregate(aggregateName)
    if (aggregateData && aggregateData.length > 0) {
      //return Number.parseInt(dataStore.getAggregate(aggregateName).reduce((acc, curr) => acc + curr[key], 0))
      return key.includes("alias")?aggregateData[aggregateData.length - 1][key]:Number(aggregateData[aggregateData.length - 1][key]).toFixed()
    }
  }
}

const getAggValue = (aggregateName, key) => {
  if (locationSelected.value) {
    const aggregateData = useCustomStore.getAggregate(aggregateName)
    if (aggregateData && aggregateData.length > 0) {
      return Number(aggregateData[0][key]).toFixed(2)
    }
  } else {
    const aggregateData = dataStore.getAggregate(aggregateName)
    if (aggregateData && aggregateData.length > 0) {
      return Number(aggregateData[0][key]).toFixed(2)
    }
  }
}

const getPredictedValue = (aggregateName, key) => {
  if (locationSelected.value) {
    const aggregateData = useCustomStore.getAggregate(aggregateName)
    if (aggregateData && aggregateData.length > 0) {
      return key.includes("alias")?aggregateData[0][key]:Number(aggregateData[0][key]).toFixed()
    }
  } else {
    const aggregateData = dataStore.getAggregate(aggregateName)
    if (aggregateData && aggregateData.length > 0) {
      return key.includes("alias")?aggregateData[0][key]:Number(aggregateData[0][key]).toFixed()
    }
  }
}

const aggValueClass = (aggregateName, key) => {
  let aggregateData
  if (locationSelected.value) {
    aggregateData = useCustomStore.getAggregate(aggregateName)
  } else {
    aggregateData = dataStore.getAggregate(aggregateName)
  }
  if (aggregateData && aggregateData.length > 0) {
    const value = Number(aggregateData[0][key]).toFixed(2)
    if (value < 0) {
      return "text-green"
    } else if (value > 0) {
      return "text-red"
    } else {
      return "text-blue"
    }
  }
}

const aggValueClassNegative = (aggregateName, key) => {
  let aggregateData
  if (locationSelected.value) {
    aggregateData = useCustomStore.getAggregate(aggregateName)
  } else {
    aggregateData = dataStore.getAggregate(aggregateName)
  }
  if (aggregateData && aggregateData.length > 0) {
    const value = Number(aggregateData[0][key]).toFixed(2)
    if (value < 0) {
      return "text-red"
    } else if (value > 0) {
      return "text-green"
    } else {
      return "text-blue"
    }
  }
}

const panelOpen = ref("clock")

const isPanelOpen = computed (() => {
  return panelOpen.value
})

const openPanel = (panelName) => {
  if (panelOpen.value === panelName) {
    panelOpen.value = ""
  } else {
    panelOpen.value = panelName
  }
}

onMounted(() => {
  map.value.on("mapfeaturesselected", handleUpdatedFeatures)
  map.value.on("deckfeaturesselected", handleUpdatedFeatures)
})

const asBearerToken = (token) => {
  return `Bearer ${token}`
}

const createLocationItem = (name, id) => {
  const timestamp = new Date().toISOString()
  const editingUser = authService.keycloak.idTokenParsed ? authService.keycloak.idTokenParsed.email : "unknown"

  return [{name: name, hex_id: id, created: timestamp, edited_by: editingUser}]
}

const handleHexagonNaming = (name, id) => {
  authService.keycloak.updateToken(90)
  const token = asBearerToken(authService.keycloak.token)
  const hexagonEndpoint = process.env.VUE_APP_NAME_LOCATION_API_B
  const postMessageToAPI = (API, logMessage) => {
    const customer = authService.keycloak.tokenParsed.customer.external_customer_reference

    API.postMessage({
      method: "setHexagonName",
      args: [token, hexagonEndpoint,
        {
          customer: customer
        },
        createLocationItem(name, id)
      ]
    })
    log("info", logMessage)
  }

  postMessageToAPI(nameHexagonAPI, `Change the name of ${id} to ${name} `)
  useCustomStore.setAggregateProp("locationAggregates", "hexagons", id, "hexagon_name", name)
}

const checkIfItemChanged = (id) => {
  return hasChanged.value.includes(id)
}

const onInputBlur = (id, evt)  => {
  if (evt.target.value) {
    handleHexagonNaming(evt.target.value, id)
    hasChanged.value.push(id)
    nameModel.value[id] = evt.target.value
  }
}

const setIdAsUnchanged = (id) => {
  const index = hasChanged.value.indexOf(id)
  hasChanged.value.splice(index, 1)
}

const resolvedNames = ref({})

const getDisplayName = async (hexId) => {
  if (!resolvedNames.value[hexId]) {
    // Check cache first
    const cachedName = h3Cache.get(hexId)
    if (cachedName) {
      resolvedNames.value[hexId] = cachedName
    } else {
      try {
        resolvedNames.value[hexId] = await humanFriendlyH3Name(hexId)
      } catch (error) {
        console.error(`Failed to resolve name for ${hexId}:`, error)
        resolvedNames.value[hexId] = hexId
      }
    }
  }
  return resolvedNames.value[hexId]
}

watch(
    () => activeOnEdit.value,
    async (newValue) => {
      if (newValue && newValue.hexagons) {
        await getDisplayName(newValue.hexagons)
      }
    },
    { immediate: true }
)
</script>


<template>
  <template v-if="locationSelected">
    <div class="mappy-location-namer">
      <v-text-field
        v-model="nameModel[activeOnEdit.hexagons]"
        variant="underlined"
        class="editable-data-item"
        density="compact"
        :placeholder="!activeOnEdit.hexagon_name || activeOnEdit.hexagon_name === '' ? 'Name this location' : ''"
        @blur="onInputBlur(activeOnEdit.hexagons, $event)"
      >
        <template #label>
          <span @click="setIdAsUnchanged(activeOnEdit.hexagons)">
            {{ !activeOnEdit.hexagon_name || activeOnEdit.hexagon_name === '' ?
              (resolvedNames[activeOnEdit.hexagons] || 'Loading...') :
              activeOnEdit.hexagon_name }}
          </span>
        </template>
      </v-text-field>
    </div>
    <LocationAggregatePanel
      icon-type="clock"
      :is-open="isPanelOpen==='clock'"
      @opened="openPanel"
    >
      <template #mainContent>
        <AggregateCard class="mappy-location-aggregate-card row-direction">
          <template #content>
            <div class="mappy-aggregate-card">
              <div class="mappy-col-1 col-with-border">
                <h6>{{ translate("data->Yesterday's standstill") }}</h6>
                <h6 />
                <h4>
                  {{ yesterdayAggregate('duration7DaysAggregates', 'hours_per_day_alias') }}
                </h4>
              </div>
              <div class="mappy-col-2">
                <h6>{{ translate("data->Total fleet") }}</h6>
                <h4>
                  {{ yesterdayAggregate('duration7DaysAggregates', 'no_of_vehicles') }} {{ translate("data->vehicles") }}
                </h4>
              </div>
            </div>
          </template>
        </AggregateCard>
        <DraggableChart
          v-if="dataReadyForMap('duration7DaysAggregates')"
          :style="{position:'relative'}"
          class="popup-draggable-chart"
          :initial-x="-60"
          :initial-y="60"
          :width="chartWidth"
          :height="chartHeight"
        >
          <AggregateCard>
            <template #content>
              <div class="mappy-chart-title">
                <h6>
                  {{ translate("data->Standstill time percentage") }}
                </h6>
                <i>{{ translate("data->last 8 days") }}</i>
              </div>
              <bar-chart-with-popup
                v-if="dataReadyForMap('duration7DaysAggregates')"
                :named-module="props.namedModule"
                colour-theme-bars="rgb(255, 0, 128)"
                colour-theme-popup="rgb(191, 0, 79)"
                get-state-function="getAggregate"
                y-axis-label=""
                graph-title=""
                struct-key="duration7DaysAggregates"
                :height="150"
                :width="500"
                :use-only-x-key="true"
                y-key="hours_per_day"
                x-key="date"
                x-axis-label=""
                z-key="day"
                popup-unit=""
                tooltip-label="hours_per_day_alias"
              />
            </template>
          </AggregateCard>
        </DraggableChart>
        <AggregateCard class="mappy-location-aggregate-card row-direction">
          <template #content>
            <div class="mappy-aggregate-card">
              <div class="mappy-col-1 col-with-border">
                <h6>{{ translate("data->Total standstill") }}</h6>
                <i>{{ translate("data->last 8 days") }}</i>
                <h4>
                  {{ summedTotal('duration7DaysAggregates', 'total_duration_alias') }}
                </h4>
              </div>
              <div class="mappy-col-2">
                <h4 :class="aggValueClass('comparedDuration7DaysAggregates', 'duration_compared_to_last_week')">
                  {{ getAggValue('comparedDuration7DaysAggregates', 'duration_compared_to_last_week') }} %
                </h4>
                <i>{{ translate("data->Compared to last period") }}</i>
              </div>
            </div>
          </template>
        </AggregateCard>
        <AggregateCard class="mappy-location-aggregate-card column-direction">
          <template #content>
            <div class="mappy-aggregate-card">
              <div class="mappy-col-1">
                <h6>
                  {{ translate("data->Standstill this calendar month") }}
                </h6>
                <i>({{ getPredictedValue('durationPrediction', 'days') }} {{ translate("data->days") }})</i>
                <h4 class="days-value">
                  {{ getPredictedValue('durationPrediction', 'duration_this_month_alias') }}
                </h4>
              </div>
              <div class="mappy-col-2">
                <h4 :class="aggValueClass('durationPrediction', 'compared_to_month_before')">
                  {{ getAggValue('durationPrediction', 'compared_to_month_before') }} %
                </h4>
                <i>{{ translate("data->Compared to last period") }}</i>
              </div>
            </div>
            <div class="mappy-aggregate-card">
              <div
                class="days-passed"
                :style="{ width: `${(31 / getPredictedValue('durationPrediction', 'days')) * 100}%` }"
              />
            </div>
            <div class="mappy-aggregate-card">
              <div class="mappy-col-1">
                <h6>{{ translate("data->Standstill estimate for this calendar month") }}</h6>
                <h4>{{ getPredictedValue('durationPrediction', 'this_month_prediction_alias') }} </h4>
              </div>
            </div>
          </template>
        </AggregateCard>
      </template>
    </LocationAggregatePanel>
    <LocationAggregatePanel
      :is-open="isPanelOpen==='scale-unbalanced-flip'"
      icon-type="scale-unbalanced-flip"
      @opened="openPanel"
    >
      <template #mainContent>
        <AggregateCard class="mappy-location-aggregate-card row-direction">
          <template #content>
            <div class="mappy-aggregate-card">
              <div class="mappy-col-1 col-with-border">
                <h6>{{ translate("data->Yesterday's unloaded weight") }}</h6>
                <h4>
                  {{ yesterdayAggregate('weight7DaysAggregates', 'unloaded_per_day') }} {{ translate("data->tonnes") }}
                </h4>
              </div>
              <div class="mappy-col-2">
                <h6>{{ translate("data->Total fleet") }}</h6>
                <h4>
                  {{ yesterdayAggregate('weight7DaysAggregates', 'no_of_vehicles') }} {{ translate("data->vehicles") }}
                </h4>
              </div>
            </div>
          </template>
        </AggregateCard>
        <DraggableChart
          v-if="dataReadyForMap('weight7DaysAggregates')"
          :style="{position:'relative'}"
          class="popup-draggable-chart"
          :initial-x="-60"
          :initial-y="60"
          :width="chartWidth"
          :height="chartHeight"
        >
          <AggregateCard>
            <template #content>
              <div class="mappy-chart-title">
                <h6>
                  {{ translate("data->Weight unloaded") }}
                </h6>
                <i>{{ translate("data->last 8 days") }}</i>
              </div>
              <bar-chart-with-popup
                v-if="dataReadyForMap('weight7DaysAggregates')"
                :named-module="props.namedModule"
                colour-theme-bars="rgb(255, 0, 128)"
                colour-theme-popup="rgb(191, 0, 79)"
                get-state-function="getAggregate"
                y-axis-label=""
                graph-title=""
                struct-key="weight7DaysAggregates"
                :height="150"
                :width="500"
                :use-only-x-key="true"
                y-key="unloaded_per_day"
                x-key="date"
                x-axis-label=""
                z-key="day"
                popup-unit="tonnes"
                tooltip-label="unloaded_per_day"
              />
            </template>
          </AggregateCard>
        </DraggableChart>
        <AggregateCard class="mappy-location-aggregate-card row-direction">
          <template #content>
            <div class="mappy-aggregate-card">
              <div class="mappy-col-1 col-with-border">
                <h6>{{ translate("data->Total weight unloaded") }}</h6>
                <i>{{ translate("data->last 8 days") }}</i>
                <h4>
                  {{ summedTotal('weight7DaysAggregates', 'unloaded_per_day') }} {{ translate("data->tonnes") }}
                </h4>
              </div>
              <div class="mappy-col-2">
                <h4 :class="aggValueClassNegative('comparedWeight7DaysAggregates', 'weight_compared_to_last_week')">
                  {{ getAggValue('comparedWeight7DaysAggregates', 'weight_compared_to_last_week') }} %
                </h4>
                <i>{{ translate("data->Compared to last period") }}</i>
              </div>
            </div>
          </template>
        </AggregateCard>
        <AggregateCard class="mappy-location-aggregate-card column-direction">
          <template #content>
            <div class="mappy-aggregate-card">
              <div class="mappy-col-1">
                <h6>
                  {{ translate("data->Weight unloaded this calendar month") }}
                </h6>
                <i>({{ getPredictedValue('weightPrediction', 'days') }} {{ translate("data->days") }})</i>
                <h4 class="days-value">
                  {{ getPredictedValue('weightPrediction', 'unloaded_this_month') }} {{ translate("data->tonnes") }}
                </h4>
              </div>
              <div class="mappy-col-2">
                <h4 :class="aggValueClassNegative('weightPrediction', 'compared_to_month_before')">
                  {{ getAggValue('weightPrediction', 'compared_to_month_before') }} %
                </h4>
                <i>{{ translate("data->Compared to last period") }}</i>
              </div>
            </div>
            <div class="mappy-aggregate-card">
              <div
                class="days-passed"
                :style="{ width: `${(31 / getPredictedValue('weightPrediction', 'days')) * 100}%` }"
              />
            </div>
            <div class="mappy-aggregate-card">
              <div class="mappy-col-1">
                <h6>{{ translate("data->Weight estimate for this calendar month") }}</h6>
                <h4>{{ getPredictedValue('weightPrediction', 'this_month_prediction') }} {{ translate("data->tonnes") }}</h4>
              </div>
            </div>
          </template>
        </AggregateCard>
      </template>
    </LocationAggregatePanel>
    <LocationAggregatePanel
      :is-open="isPanelOpen==='magnifying-glass-chart'"
      icon-type="magnifying-glass-chart"
      @opened="openPanel"
    >
      <template #mainContent>
        <DraggableChart
          v-if="dataReadyForMap('weekdaychartongoingstops')"
          :style="{position:'relative'}"
          class="popup-draggable-chart"
          :initial-x="-60"
          :initial-y="60"
          :width="chartWidth"
          :height="chartHeight"
        >
          <AggregateCard>
            <template #content>
              <toggle-visibility-btn
                toggle-for="Vehicles stopped"
                :is-visible="showThis('weekday')"
                class="chart-visibility-btn"
                @toggle-visibility="charts.weekday.showThis = !charts.weekday.showThis"
              />
              <weekday-chart
                :show-this="showThis('weekday')"
                :named-module="props.namedModule"
                graph-title="Vehicle stopped"
                struct-key="weekdaychartongoingstops"
                y-key="name_of_day"
                z-key="median"
                x-key="date"
                :y-axis-label="translate('data->Weekday')"
                :x-axis-label="translate('data->Hour of day')"
              />
            </template>
          </AggregateCard>
        </DraggableChart>
        <DraggableChart
          v-if="dataReadyForMap('standstill')"
          :style="{position:'relative'}"
          class="popup-draggable-chart"
          :initial-x="-60"
          :initial-y="60"
          :width="chartWidth"
          :height="chartHeight"
        >
          <AggregateCard>
            <template #content>
              <toggle-visibility-btn
                toggle-for="Standstill duration"
                :is-visible="showThis('standstill')"
                class="chart-visibility-btn"
                @toggle-visibility="charts.standstill.showThis = !charts.standstill.showThis"
              />
              <bar-chart
                :show-this="showThis('standstill')"
                :named-module="props.namedModule"
                :y-axis-label="translate('data->Stop duration(min)')"
                graph-title=""
                struct-key="standstill"
                :use-only-x-key="true"
                y-key="median_duration"
                x-key="STOP_START_INTERVAL"
                x-axis-label=""
              />
            </template>
          </AggregateCard>
        </DraggableChart>
        <DraggableChart
          v-if="dataReadyForMap('loadChangeDistribution')"
          class="popup-draggable-chart"
          :style="{position:'relative'}"
          :initial-x="-60"
          :initial-y="60"
          :width="chartWidth"
          :height="chartHeight"
        >
          <AggregateCard>
            <template #content>
              <toggle-visibility-btn
                toggle-for="Stops of entire connected fleet"
                :is-visible="showThis('loadChange')"
                class="chart-visibility-btn"
                @toggle-visibility="charts.loadChange.showThis = !charts.loadChange.showThis"
              />
              <bar-chart
                :show-this="showThis('loadChange')"
                :named-module="props.namedModule"
                y-axis-label="Number of stops"
                graph-title=""
                struct-key="loadChangeDistribution"
                y-key="NO_STOPS"
                x-key="stop_interval"
                x-axis-label=""
              />
            </template>
          </AggregateCard>
        </DraggableChart>
      </template>
    </LocationAggregatePanel>
  </template>
  <template v-else>
    <div class="mappy-location-namer no-selection">
      <v-text-field
        variant="underlined"
        class="editable-data-item"
        density="compact"
        :model-value="title"
        readonly
      />
    </div>
    <LocationAggregatePanel
      icon-type="clock"
      :is-open="isPanelOpen==='clock'"
      :margin-right-translate="'-340px'"
      @opened="openPanel"
    >
      <template #mainContent>
        <AggregateCard class="mappy-location-aggregate-card row-direction">
          <template #content>
            <div class="mappy-aggregate-card">
              <div class="mappy-col-1 fleet-col-with-border">
                <h6>{{ translate("data->Yesterday's standstill") }}</h6>
                <h4>
                  {{ yesterdayAggregate('duration7DaysAggregates', 'hours_per_day_alias') }}
                </h4>
              </div>
              <div class="mappy-col-2">
                <h6>{{ translate("data->Total fleet") }}</h6>
                <h4>
                  {{ yesterdayAggregate('duration7DaysAggregates', 'no_of_vehicles') }} {{ translate("data->vehicles") }}
                </h4>
              </div>
            </div>
          </template>
        </AggregateCard>
        <AggregateCard class="mappy-location-aggregate-card">
          <template #content>
            <div class="mappy-fleet-chart-title">
              <h6>
                {{ translate("data->Standstill time percentage") }}
              </h6>
              <i>{{ translate("data->last 8 days") }}</i>
            </div>
            <bar-chart-with-popup
              v-if="dataReadyForMap('duration7DaysAggregates')"
              get-state-function="getAggregate"
              y-axis-label=""
              graph-title=""
              struct-key="duration7DaysAggregates"
              :height="150"
              :width="500"
              :use-only-x-key="true"
              y-key="hours_per_day"
              x-key="date"
              x-axis-label=""
              z-key="day"
              popup-unit=""
              tooltip-label="hours_per_day_alias"
            />
          </template>
        </AggregateCard>
        <AggregateCard class="mappy-location-aggregate-card row-direction">
          <template #content>
            <div class="mappy-aggregate-card">
              <div class="mappy-col-1 fleet-col-with-border">
                <h6>{{ translate("data->Total standstill") }}</h6>
                <i>{{ translate("data->last 8 days") }}</i>
                <h4>
                  {{ summedTotal('duration7DaysAggregates', 'total_duration_alias') }}
                </h4>
              </div>
              <div class="mappy-col-2">
                <h4 :class="aggValueClass('comparedDuration7DaysAggregates', 'duration_compared_to_last_week')">
                  {{ getAggValue('comparedDuration7DaysAggregates', 'duration_compared_to_last_week') }} %
                </h4>
                <i>{{ translate("data->Compared to last period") }}</i>
              </div>
            </div>
          </template>
        </AggregateCard>
        <AggregateCard class="mappy-location-aggregate-card column-direction">
          <template #content>
            <div class="mappy-aggregate-card">
              <div class="mappy-col-1">
                <h6>
                  {{ translate("data->Standstill this calendar month") }}
                </h6>
                <i>({{ getPredictedValue('durationPrediction', 'days') }} {{ translate("data->days") }})</i>
                <h4 class="fleet-days-value">
                  {{ getPredictedValue('durationPrediction', 'duration_this_month_alias') }}
                </h4>
              </div>
              <div class="mappy-col-2">
                <h4 :class="aggValueClass('durationPrediction', 'compared_to_month_before')">
                  {{ getAggValue('durationPrediction', 'compared_to_month_before') }} %
                </h4>
                <i>{{ translate("data->Compared to last period") }}</i>
              </div>
            </div>
            <div class="mappy-aggregate-card">
              <div
                class="fleet-days-passed"
                :style="{ width: `${(31 / getPredictedValue('durationPrediction', 'days')) * 100}%` }"
              />
            </div>
            <div class="mappy-aggregate-card">
              <div class="mappy-col-1">
                <h6>{{ translate("data->Standstill estimate for this calendar month") }}</h6>
                <h4>{{ getPredictedValue('durationPrediction', 'this_month_prediction_alias') }} </h4>
              </div>
            </div>
          </template>
        </AggregateCard>
      </template>
    </LocationAggregatePanel>

    <LocationAggregatePanel
      :is-open="isPanelOpen==='scale-unbalanced-flip'"
      :margin-right-translate="'-340px'"
      icon-type="scale-unbalanced-flip"
      @opened="openPanel"
    >
      <template #mainContent>
        <AggregateCard class="mappy-location-aggregate-card row-direction">
          <template #content>
            <div class="mappy-aggregate-card">
              <div class="mappy-col-1 fleet-col-with-border">
                <h6>{{ translate("data->Yesterday's unloaded weight") }}</h6>
                <h4>
                  {{ yesterdayAggregate('weight7DaysAggregates', 'unloaded_per_day') }} {{ translate("data->tonnes") }}
                </h4>
              </div>
              <div class="mappy-col-2">
                <h6>{{ translate("data->Total fleet") }}</h6>
                <h4>
                  {{ yesterdayAggregate('weight7DaysAggregates', 'no_of_vehicles') }} {{ translate("data->vehicles") }}
                </h4>
              </div>
            </div>
          </template>
        </AggregateCard>
        <AggregateCard class="mappy-location-aggregate-card">
          <template #content>
            <div class="mappy-fleet-chart-title">
              <h6>
                {{ translate("data->Weight unloaded") }}
              </h6>
              <i>{{ translate("data->last 8 days") }}</i>
            </div>
            <bar-chart-with-popup
              v-if="dataReadyForMap('weight7DaysAggregates')"
              get-state-function="getAggregate"
              y-axis-label=""
              graph-title=""
              struct-key="weight7DaysAggregates"
              :height="150"
              :width="500"
              :use-only-x-key="true"
              y-key="unloaded_per_day"
              x-key="date"
              x-axis-label=""
              z-key="day"
              popup-unit="tonnes"
              tooltip-label="unloaded_per_day"
            />
          </template>
        </AggregateCard>
        <AggregateCard class="mappy-location-aggregate-card row-direction">
          <template #content>
            <div class="mappy-aggregate-card">
              <div class="mappy-col-1 fleet-col-with-border">
                <h6>{{ translate("data->Total weight unloaded") }}</h6>
                <i>{{ translate("data->last 8 days") }}</i>
                <h4>
                  {{ summedTotal('weight7DaysAggregates', 'unloaded_per_day') }} {{ translate("data->tonnes") }}
                </h4>
              </div>
              <div class="mappy-col-2">
                <h4 :class="aggValueClassNegative('comparedWeight7DaysAggregates', 'weight_compared_to_last_week')">
                  {{ getAggValue('comparedWeight7DaysAggregates', 'weight_compared_to_last_week') }} %
                </h4>
                <i>{{ translate("data->Compared to last period") }}</i>
              </div>
            </div>
          </template>
        </AggregateCard>
        <AggregateCard class="mappy-location-aggregate-card column-direction">
          <template #content>
            <div class="mappy-aggregate-card">
              <div class="mappy-col-1">
                <h6>
                  {{ translate("data->Weight unloaded this calendar month") }}
                </h6>
                <i>({{ getPredictedValue('weightPrediction', 'days') }} {{ translate("data->days") }})</i>
                <h4 class="fleet-days-value">
                  {{ getPredictedValue('weightPrediction', 'unloaded_this_month') }} {{ translate("data->tonnes") }}
                </h4>
              </div>
              <div class="mappy-col-2">
                <h4 :class="aggValueClassNegative('weightPrediction', 'compared_to_month_before')">
                  {{ getAggValue('weightPrediction', 'compared_to_month_before') }} %
                </h4>
                <i>{{ translate("data->Compared to last period") }}</i>
              </div>
            </div>
            <div class="mappy-aggregate-card">
              <div
                class="fleet-days-passed"
                :style="{ width: `${(31 / getPredictedValue('weightPrediction', 'days')) * 100}%` }"
              />
            </div>
            <div class="mappy-aggregate-card">
              <div class="mappy-col-1">
                <h6>{{ translate("data->Weight estimate for this calendar month") }}</h6>
                <h4>{{ getPredictedValue('weightPrediction', 'this_month_prediction') }} {{ translate("data->tonnes") }}</h4>
              </div>
            </div>
          </template>
        </AggregateCard>
      </template>
    </LocationAggregatePanel>
  </template>
</template>

<style scoped>
.chart-visibility-btn {
  width: 100%;
}

.popup-draggable-chart {
  flex-direction: column !important;
  z-index: 1 !important;
}

.mappy-aggregate-card {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.mappy-location-aggregate-card {
  transform: translate(-60px, 60px);
  position: relative;
  width: 400px;
}

.row-direction {
  display: flex;
  flex-direction: row;
}

.column-direction {
  display: flex;
  flex-direction: column;
}

.mappy-col-1 {
  height: 100%;
  width: 49%;
  align-self: flex-end;
  padding: 8px;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  text-align: left;
}

.col-with-border {
  border-left: solid 4px rgb(255, 0, 128);
}

.fleet-col-with-border {
  border-left: solid 4px var(--tds-blue-400);
}

.mappy-col-2 {
  height: 100%;
  width: 49%;
  align-self: flex-end;
  display: flex;
  padding: 8px;
  align-items: flex-end;
  flex-direction: column;
  text-align: end;
}

.days-passed {
  height: 10px;
  margin: 1px 8px 10px;
  border-radius: 5px;
  background-color: rgb(255, 0, 128);
}

.days-value {
  color: rgb(255, 0, 128);
}

.fleet-days-passed {
  height: 10px;
  margin: 1px 8px 10px;
  border-radius: 5px;
  background-color: var(--tds-blue-400);
}

.fleet-days-value {
  color: var(--tds-blue-400);
}
i {
  font-size: 12px;
}

.text-red {
  color: var(--tds-red-400);
}

.text-blue {
  color: var(--tds-blue-400);
}

.text-green {
  color: var(--tds-green-400);
}

.mappy-chart-title {
  align-self: baseline;
  text-align: justify;
  justify-self: flex-start;
  border-left: solid 4px rgb(255, 0, 128);
  padding-bottom: 15px;
  padding-left: 8px;
}

.mappy-fleet-chart-title {
  align-self: baseline;
  text-align: justify;
  justify-self: flex-start;
  border-left: solid 4px var(--tds-blue-400);
  padding-bottom: 15px;
  padding-left: 8px;
}

.mappy-location-namer {
  position: absolute;
  color: white;
  background-color: rgba(var(--tds-blue-800), 1);
  top: 70px;
  right: 60px;
  width: 220px;
  height: 40px;
  z-index: 3;
  padding-left: 5px;
}

.no-selection {
  width: 280px;
}

#list-activator {
  padding: 2px;
  cursor: pointer;
}

#stored-new-name {
  color: var(--tds-green-400);
}
</style>