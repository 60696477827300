<script setup>
import SideMenu from "@/components/SideMenu"
import SideMenuItems from "@/components/SideMenuItems"
import MenuItem from "@/components/MenuItem"
import {inject, ref, watch, computed} from "vue"
import MapLayerSettings from "@/components/MapLayerSettings"
import MapLegend from "@/components/MapLegend"
import MapDataSettings from "@/components/MapDataSettings.vue"
import FlowLayerStyle from "@/components/FlowLayerStyle.vue"
import {useMap} from "@/composables"
import ChartControl from "@/components/ChartControl.vue"
import {useMainStore} from "@/store/mainStore"
import {useSetStore} from "@/store/setStore"
import {createCustomMapStore} from "@/store/customMapStore"
import { log } from "@/plugin/logger.js"
import RangeSliderFilter from "@/components/RangeSliderFilter.vue"
import MultiSelectPolygon from "@/components/MultiSelectPolygon.vue"
import CustomerFleetFilter from "@/components/CustomerFleetFilter.vue"
import {authService} from "@/services/AuthService"
import {useI18nStore} from "@/store/localeStore"

const props = defineProps({
  layers: {
    type: Array,
    required: true
  },
  hexagonLayers: {
    type: Array,
    required: true
  },
  flowLayers: {
    type: Array,
    required: true
  },
  namedModule: {
    type: String,
    required: true
  },
  dataSetsGetter : {
    type: String,
    default: "getDataSetOptionsForMap"
  },
  openCharts: {
    type: Boolean,
    required: true
  }
})

const useCustomStore = inject(props.namedModule)
const { map } = useMap()
const showLabel = ref(false)
const mainStore = useMainStore()
const dataStore = useSetStore()
const userOpenedCharts = computed(() => {
  return props.openCharts
})
const i18nStore = useI18nStore()
const translate = (key) => {
  return i18nStore.getTranslation(key)
}

const mapSettings = {
  layerSettings: ref(),
  legend: ref(),
  dataSettings: ref(),
  charts: ref(),
  dataFilter: ref()
}
const filterLocationsOn = ref("sum_duration")
const clickedMenuItem = ref(props.openCharts ? "charts" : "initial")
const openMenuItemATCoord = ref({top: "0px", right: "0px"})
const mapViewName = ref(props.namedModule === "mainMap" || props.namedModule === "mapState_1"  ? "Last week" : "Name your view")

const openMenuFor = (menuItemClicked, event, isToggle = true) => {
  if (props.openCharts && clickedMenuItem.value === "initial" && menuItemClicked === "charts") {
    clickedMenuItem.value = menuItemClicked
  } else if (isToggle) {
    clickedMenuItem.value === menuItemClicked ?
      clickedMenuItem.value = "" :
      clickedMenuItem.value = menuItemClicked
  } else {
    clickedMenuItem.value = menuItemClicked
  }

  // Check if event has the properties you expect, otherwise use default/fallback values
  if (clickedMenuItem.value && event.target) {
    const target = event.target.getBoundingClientRect ? event.target.getBoundingClientRect() : {y: 0, width: 0}
    const targetPane = event.target.closest ? event.target.closest(".splitpanes__pane").getBoundingClientRect() : {y: 0}
    openMenuItemATCoord.value.top = (target.y - targetPane.y) + "px"
    openMenuItemATCoord.value.right = "70.4px"
  } else {
    // Fallback or default positioning
    openMenuItemATCoord.value.top = "62px"
    openMenuItemATCoord.value.right = "70.4px"
  }

  if(clickedMenuItem.value === "layerSettings"){
    let createArrayFilterObjects = {}
    let arrLayers= props.layers
    arrLayers.map(layer => {
      const layerStringName = props.namedModule + "_" + layer.layerName
      createArrayFilterObjects[layerStringName] = []
    })
    useCustomStore.setFilterChildObject(mergeObjectsArrays(useCustomStore.getFilterChildObject, createArrayFilterObjects))
    }
}
const useMapStore = createCustomMapStore(props.namedModule)
const toggleNewLocationRangeSet = (range) => {
  const hexagonsData = dataStore.getAggregate(range.layerName + "Unfiltered")
  const from = parseFloat(range.min)
  const to = parseFloat(range.max)
  const filteredData = hexagonsData.filter(d => {
    const filterOn = parseFloat(d[filterLocationsOn.value])
    return filterOn >= from && filterOn <= to
  })
  handleDataFiltered(filteredData, range.layerName)
}
const handleDataFiltered = (dataFiltered, aggregateName) => {
  log("info", "Range was set for the data set")
  useMapStore.setAggregate(aggregateName, dataFiltered)
}

function mergeObjectsArrays(a, b) {
  const result = { ...a }
  for (let key in b) {
    if (a.hasOwnProperty(key)) {
      result[key] = result[key].concat(b[key])
    } else {
      result[key] = b[key]
    }
  }
  return result
}
function viewNameChanged(e) {
  mapViewName.value=e.target.value
}

watch(userOpenedCharts,(newVal, oldVal) => {
  if(newVal && clickedMenuItem.value === "initial") {
    clickedMenuItem.value = "charts"
  } else if(newVal) {
    openMenuFor("charts", {}, false)
  } else {
    if(clickedMenuItem.value !== "charts") {
      clickedMenuItem.value = ""
    }
  }
})

const isChartsOpen = computed(() => {
  return clickedMenuItem.value === "charts" || (props.openCharts && clickedMenuItem.value === "initial")
})

</script>

<template>
  <tds-text-field
    v-if="showLabel"
    id="map-view-name"
    class="map-view-name-text-field"
    name="map-view-name"
    no-min-width="true"
    type="text"
    size="sm"
    :value="mapViewName"
    @input="viewNameChanged"
  />
  <SideMenuItems>
    <template #menu-item-list>
      <MenuItem v-show="false"
        v-if="mainStore.getAppConfigFlag('isTest', authService.getUserRoles(), authService.getUserEmail()) && props.namedModule === 'mainMap'"
        :ref="mapSettings.dataFilter"
        icon-name="filter"
        :clicked-styling="clickedMenuItem === 'dataFilter'"
        @click="openMenuFor('dataFilter', $event)"
      />
      <MenuItem
        :ref="mapSettings.charts"
        icon-name="line-chart"
        :clicked-styling="(openCharts && clickedMenuItem === 'initial') || (openCharts && clickedMenuItem === 'charts') || (!openCharts && clickedMenuItem === 'charts')"
        @click="openMenuFor('charts', $event)"
      />
      <MenuItem
        v-if="mainStore.getAppConfigFlag('isTest', authService.getUserRoles(), authService.getUserEmail())"
        :ref="mapSettings.layerSettings"
        icon-name="layer-group"
        :clicked-styling="clickedMenuItem === 'layerSettings'"
        @click="openMenuFor('layerSettings', $event)"
      />
      <MenuItem
        :ref="mapSettings.legend"
        icon-name="palette"
        :clicked-styling="clickedMenuItem === 'legend'"
        @click="openMenuFor('legend', $event)"
      />
      <MenuItem
        v-if="mainStore.getAppConfigFlag('isTest', authService.getUserRoles(), authService.getUserEmail())"
        :ref="mapSettings.dataSettings"
        icon-name="file-import"
        :clicked-styling="clickedMenuItem === 'dataSettings'"
        @click="openMenuFor('dataSettings', $event)"
      />
      <MenuItem
        v-if="props.hexagonLayers.length>0"
        :ref="mapSettings.multiselect"
        icon-name="draw-polygon"
        :clicked-styling="clickedMenuItem === 'multiselect'"
        @click="openMenuFor('multiselect', $event)"
      />
    </template>
  </SideMenuItems>
  <SideMenu
    v-show="false"
    :style-top="openMenuItemATCoord.top"
    :style-right="openMenuItemATCoord.right"
  >
    <template #main>
      <CustomerFleetFilter
        :named-module="props.namedModule"
      />
    </template>
  </SideMenu>
  <ChartControl
    v-if="(openCharts && clickedMenuItem === 'initial') || (openCharts && clickedMenuItem === 'charts') || (!openCharts && clickedMenuItem === 'charts')"
    :named-module="props.namedModule"
  />
  <SideMenu
    v-if="clickedMenuItem === 'layerSettings'"
    :style-top="openMenuItemATCoord.top"
    :style-right="openMenuItemATCoord.right"
  >
    <template #main>
      <MapLayerSettings
        v-for="(layer, index) in layers"
        v-show="clickedMenuItem==='layerSettings'"
        :key="index+'_'+layer.layerName"
        :layer-name="layer.layerName"
        :layer-type="layer.canBe"
        :layer-data-for-domain="layer.accessorInState"
        :accessor-key-for-data-function="layer.accessorKey"
        :named-module="props.namedModule"
      />
      <tds-block
        v-if="layers.length < 1"
        style="max-width: 150px"
      >
        <h6
          style="font-size: 12px; color: black"
        >
          {{ translate("data->There is no map layer added.") }} <br>Click
          <font-awesome-icon
            icon="file-import"
            style="cursor: default;color: deeppink;height: 15px;"
            @click="openMenuFor('dataSettings', $event)"
          /> {{ translate("data->to add data to the map.") }}
        </h6>
      </tds-block>
    </template>
  </SideMenu>

  <SideMenu
    v-show="clickedMenuItem === 'legend'"
    :style-top="openMenuItemATCoord.top"
    :style-right="openMenuItemATCoord.right"
  >
    <template #main>
      <MapLegend
        v-for="(layer, index) in hexagonLayers"
        v-show="clickedMenuItem==='legend'"
        :key="index+'_'+layer"
        :layer-name="layer.layerName"
        :layer-data-for-domain="layer.accessorInState"
        :accessor-key-for-data-function="layer.accessorKey"
        :key-as-string="layer.colourKey"
        :elev-key-as-string="layer.elevationKey"
        :elev-scale-as-string="layer.elevationScale"
        :color-schema="layer.colourSchema"
        :color-count="layer.colourCount"
        :color-scheme-name="layer.colorSchemeName"
        :named-module="props.namedModule"
      />
      <FlowLayerStyle
        v-for="(layer, index) in flowLayers"
        v-show="clickedMenuItem==='legend'"
        :key="index+'_'+layer"
        :layer-name="layer.layerName"
        :layer-data-for-domain="layer.accessorInState"
        :accessor-key-for-data-function="layer.accessorKey"
        :key-as-string="layer.colourKey"
        :colour-scheme-name="layer.colourSchemeName"
        :named-module="props.namedModule"
      />
      <tds-block
        v-if="layers.length < 1"
        style="max-width: 150px"
      >
        <h6
          style="font-size: 12px;  color: black"
        >
          There is no map layer added. <br>Click  <font-awesome-icon
            icon="file-import"
            style="cursor: default;color: deeppink;height: 15px;"
            @click="openMenuFor('dataSettings', $event)"
          /> to add data to the map.
        </h6>
      </tds-block>
    </template>
  </SideMenu>
  <SideMenu
    v-show="clickedMenuItem === 'dataSettings'"
    :style-top="openMenuItemATCoord.top"
    :style-right="openMenuItemATCoord.right"
  >
    <template #main>
      <MapDataSettings
        :named-module="props.namedModule"
        :data-sets-getter="props.dataSetsGetter"
      />
    </template>
  </SideMenu>
  <SideMenu
    v-show="clickedMenuItem === 'multiselect'"
    :style-top="openMenuItemATCoord.top"
    :style-right="openMenuItemATCoord.right"
  >
    <template #main>
      <div
        style="height: 275px;margin: 9px;"
        class="tds-block"
      >
        <MultiSelectPolygon />
        <h6
          class="tds-headline-06"
          style="margin-top: 10px;display: flex;justify-content: center;"
        >
          {{ translate("data->Slide to filter locations") }}
        </h6>
        <RangeSliderFilter
          v-for="(layer, index) in hexagonLayers"
          :key="layer.setName + '_' + index + '_filter_hexagon_layer'"
          :layer="layer"
          :range-prop="filterLocationsOn"
          :named-module="props.namedModule"
          @set-new-range="toggleNewLocationRangeSet"
        />
      </div>
    </template>
  </SideMenu>
</template>
<style scoped>

.map-view-name-text-field {
  opacity: 70%;
  --tds-text-field-data-color: white;
  --tds-text-field-background: #0d0f13;
  --tds-text-field-border-bottom: #0d0f13;
  --tds-text-field-border-bottom-hover: deeppink;
  border-radius: 4px;
  top: 18px;
  right: 55px;
  position: absolute;
  text-align: center;
  margin-bottom: 20px;
}

</style>
