<script setup>
import registerNewUser from "@/components/RegisterNewUser.vue"
import { log } from "@/plugin/logger"
import { authService } from "@/services/AuthService"
import { useMainStore } from "@/store/mainStore"
import syncMaps from "@mapbox/mapbox-gl-sync-move"
import "splitpanes/dist/splitpanes.css"
import "video.js/dist/video-js.css"
import { computed, onMounted, ref, watch } from "vue"
import { useRouter } from "vue-router"
import {useI18nStore} from "@/store/localeStore"
const mainStore = useMainStore()
const panesNumber = ref(1)
const syncedMaps = ref()
const baseMaps = ref([])
const router = useRouter()
const i18nStore = useI18nStore()
const translate = (key) => {
  return i18nStore.getTranslation(key)
}
const baseMapStyles = computed(() => {
  return mainStore.getBasemapStyles
})

function addNewBaseMap (baseMap) {
  baseMaps.value.push(baseMap)
}

function removeBaseMap () {
  baseMaps.value = baseMaps.value.slice(0, baseMaps.value.length - 1)
}
const store = useMainStore()
const requiredRole = process.env.VUE_APP_KEYCLOAK_REQUIRED_ROLE
const hasRole = authService.keycloak.hasResourceRole(requiredRole)
const isAuthorized = authService.keycloak.authenticated && hasRole
const registerPanel = ref(false)
const HORequiredRole = [process.env.VUE_APP_AZUREAD_REQUIRED_ROLE]
const isHOAuthorized = !!(authService.hasADRoles(HORequiredRole) && authService.getADUser())
const run = () => {
  if (isAuthorized) {
    router.push({ name: "CustomerMapView" })
    return true
  }
  if (isHOAuthorized) {
    router.push({ name: "FleetView" })
    return true
  }
  if(!isAuthorized) return
}

onMounted(() => {
  log("info", "Initiated the 'EmptyMapView' component.")
  run()
})

watch(
    () => baseMaps.value.length,
    () => {
      if (baseMaps.value.length > 1) {
        if (typeof syncedMaps.value === "function") {
          syncedMaps.value()
        }
        syncedMaps.value = syncMaps(baseMaps.value)
      }
    }
)
const loginWithKeycloak = () => {
  authService.signInWithKeycloak().then((authenticated) => {
    if (authenticated) {
      store.setBearerToken(authService.keycloak.token)
      router.push({ name: "CustomerMapView" })

    }
    setAuthText()
  })
}
const registerPanelVisible = () => {
  registerPanel.value=true
}
const registerPanelClose = () => {
  registerPanel.value=false
}
const loggedInWith = ref("")
const setAuthText = () => {
  if (authService.keycloak.authenticated) {
    const userEmail = authService.keycloak.idTokenParsed ? authService.keycloak.idTokenParsed.email : JSON.stringify((authService.keycloak))
    if (!authService.keycloak.hasResourceRole(requiredRole)) {
      log("info", userEmail + " tried to get into mappy, but is lacking access.")
      loggedInWith.value = "Sorry " + userEmail + ", you haven't unlocked mappy yet😳. Reach out to Sara Sylvan to learn how to get access."
    }
    else if (authService.keycloak.hasResourceRole(requiredRole)) {
      loggedInWith.value = userEmail + " is logged with customer id."
    }
  }
  else if (authService.getADUser()) {
    const userEmail = authService.getADUser() ? authService.getADUser() : JSON.stringify((authService.login))
    if (!authService.hasADRoles(HORequiredRole)) {
      log("info", userEmail + " tried to get into mappy via Azure, but is lacking access.")
      loggedInWith.value = "Sorry " + userEmail + ", you haven't unlocked mappy yet😳. Reach out to Sara Sylvan to learn how to get access."
    }
    else if (authService.hasADRoles(HORequiredRole)) {
      loggedInWith.value = userEmail + " is logged in with global."
    }
  }
}
</script>

<template>
  <div class="main-container">
    <div class="sidebar">
      <!-- Sidebar content here -->
    </div>
    <div class="content">
      <slot v-if="!isAuthorized">
        <div class="welcome-div">
          <div class="welcome-message">
            <div
              v-show="!registerPanel"
              class="welcome-message-inner"
            >
              <br>
              <div class="already-user">
                <h1><strong>{{ translate("data->Already a user!") }}</strong></h1>
                <a
                  class="cta-button"
                  @click="loginWithKeycloak()"
                >{{ translate("data->Login") }}</a>
              </div>
              <br>
              <div class="new-user">
                <h1><strong>{{ translate("data->New here") }}!</strong></h1>
                <h2><strong>{{ translate("data->Want to know more?") }}</strong></h2>
                <br>
                <a
                  class="cta-button"
                  @click="registerPanelVisible()"
                >{{ translate("data->Register") }}</a>
              </div>
            </div>
            <div
              v-show="registerPanel"
              class="register-panel"
            >
              <label
                class="close-button"
                @click="registerPanelClose()"
              >X</label>
              <register-new-user />
            </div>
          </div>
          <div class="video-container">
            <video
              src="../assets/Mappy_welcome_video.mp4"
              poster="../assets/mappy_play.png"
              controls
              autoplay
              loop
            />
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<style>
body {
  margin: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.main-container {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.sidebar {
  width: 50px; /* Adjust width as needed */
  background-color: #041e42;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
}

.content {
  flex: 1;
  overflow-y: auto; /* Allow scrolling */
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align items to the top */
  height: 100vh; /* Ensure it takes full height */
  background-color: #041e42;
  background-image: url("../assets/basemap.png");
  background-size: cover;
  background-position: center;
  background-repeat: repeat-y;
}

.welcome-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align items to the top */
  text-align: center;
  color: white;
  font-family: "Scania Sans", Arial, Helvetica, sans-serif;
  font-weight: bold;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  background-repeat: repeat-y;
}

.welcome-message {
  background-color: #041e42;
  opacity: 0.8;
  justify-content: center;
  margin-left: 0px;
}

.welcome-message-inner {
  display: block;
  width: auto;
  padding: 20px;
}

.already-user, .new-user {
  display: block;
  margin-bottom: 20px;
}

.already-user h1, .new-user h1, .new-user h2, .new-user h4 {
  margin-bottom: 15px; /* Add space below headings */
}

.register-panel {
  display: block;
  width: auto;
  padding: 50px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align video to the top */
  width: 100%;
  max-width: 480px; /* Limit max width */
  height: 480px; /* Set fixed height for 1:1 aspect ratio */
  overflow: hidden;
  margin-top: 20px;
}

.video-container > video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cta-button {
  background-color: deeppink;
  color: white;
  padding: 10px 20px;
  font-size: 18px;
  text-decoration: none;
  font-family: "Scania Sans", Arial, Helvetica, sans-serif;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin: 20px 0; /* Increase margin to add space above and below */
}

.cta-button:hover {
  background-color: #46b8da;
}

/* Responsive styles */
@media (max-width: 480px) and (max-height: 480px) {
  .sidebar {
    width: 50px;
  }

  .cta-button {
    font-size: 16px;
    padding: 8px 16px;
  }

  .welcome-div {
    padding: 15px;
  }
}

@media (max-width: 480px) and (max-height: 480px) {
  .sidebar {
    width: 50px;
  }

  .cta-button {
    font-size: 14px;
    padding: 6px 12px;
  }

  .welcome-div {
    padding: 10px;
  }
}

/* Media query for landscape mode */
@media (orientation: landscape) {
  .welcome-div {
    flex-direction: row; /* Align items horizontally */
    justify-content: space-between; /* Space out the content and video */
    align-items: flex-start; /* Align items to the top */
  }

  .welcome-message {
    flex: 1;
    margin-right: 20px; /* Add some space between text and video */
  }

  .video-container {
    flex: 1;
    align-self: center; /* Center horizontally */
  }
}

/* Media query for portrait mode */
@media (orientation: portrait) {
  .welcome-div {
    flex-direction: column; /* Align items vertically */
    justify-content: flex-start; /* Align items to the top */
    align-items: center; /* Center items horizontally */
  }

  .welcome-message {
    margin-bottom: 20px; /* Add space between text and video */
  }

  .video-container {
    width: 100%;
    max-width: 480px; /* Limit max width */
    height: auto; /* Adjust height automatically */
  }
}
</style>
